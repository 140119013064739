import React from 'react'
import Slider from 'react-slick'
import { Header, Segment } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import { Link } from '../../locales'

const CountrySlider = ({ countries, t, i18n }) => {
  function NextArrow (props) {
    const { onClick } = props
    return (
      <i
        className={'child country--left ta-arrow-1-left icon large'}
        style={{ display: 'block', cursor: 'pointer' }}
        onClick={onClick}
      />
    )
  }

  function PrevArrow (props) {
    const { onClick } = props
    return (
      <i
        className={'child country--right ta-arrow-1-right icon large'}
        style={{ display: 'block', cursor: 'pointer' }}
        onClick={onClick}
      />
    )
  }

  const settings = {
    className: 'center',
    infinite: true,
    centerPadding: '50px',
    slidesToShow: 10,
    lazyLoad: true,
    swipeToSlide: true,
    // oposite direction due to arrow placement
    nextArrow: <PrevArrow />,
    prevArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  }

  return (
    <Segment
      inverted
      color='red'
      style={{ padding: '2em 3em' }}
      className='parent'
    >
      <Header as='h2' textAlign='center'>
        {t('nav-country.header')}:
      </Header>
      <Slider {...settings} className='country--list'>
        {countries.map(({ node: { id, title, slug } }) => (
          <div key={id}>
            <Link to={`/countries/${slug}`} lang={i18n.language}>
              <span style={{ color: '#fff' }}>{title}</span>
            </Link>
          </div>
        ))}
      </Slider>
    </Segment>
  )
}

export default withTranslation()(CountrySlider)
