import React from 'react'
import { connect } from 'react-redux'
import { Container, Grid, Dropdown, Button, Input } from 'semantic-ui-react'
import { navigate } from 'gatsby'
import { withTranslation } from 'react-i18next'

import { filtersActions } from './state'

import './Finder.css'
import { colorRed, colorWhite } from '../../utils/constants'
import { localizeLink } from '../../utils/helpers'

const Finder = ({
  setFilters,
  i18n,
  t,
  options,
  filterOptions: {
    service,
    serviceGroup,
    country,
    countryRegion,
    countryRegionSlug,
    region,
    location,
    countrySlug,
    regionSlug,
    locationSlug
  }
}) => {
  const handleChange = (e, { options, value }) => {
    const place = options.find(option => option.value === value)
    const {
      countryRegion,
      countryRegionSlug,
      country,
      region,
      location,
      countrySlug,
      regionSlug,
      locationSlug
    } = place.value ? JSON.parse(place.value) : {}

    setFilters({
      countryRegion,
      countryRegionSlug,
      country,
      region,
      location,
      countrySlug,
      regionSlug,
      locationSlug
    })
  }

  const handleNavigation = service => {
    setFilters({ service })

    let link = `/country-regions/${countryRegionSlug || 'all'}/destinations/`
    link += `${countrySlug || 'all'}/`

    if (regionSlug) {
      link += `/${service || 'all-services'}/regions/${regionSlug}/`
    } else {
      link += `${service || ''}`
    }
    if (locationSlug) {
      link += `/locations/${locationSlug}/`
    }

    return navigate(localizeLink(link, i18n.language))
  }

  return (
    <Container>
      <Grid>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={5}
            largeScreen={6}
            widescreen={6}
          >
            <label>
              <p>{t('finder.where')}</p>
              <Input fluid inverted size='big' className='finder'>
                <Dropdown
                  className='finder'
                  placeholder={t('finder.placeholder')}
                  fluid
                  search
                  selection
                  minCharacters={3}
                  options={options}
                  lazyLoad='true'
                  onChange={handleChange}
                  style={{
                    background: colorRed,
                    color: colorWhite,
                    border: '0px solid',
                    borderBottom: '1px solid #fff'
                  }}
                />
              </Input>
            </label>
          </Grid.Column>

          <Grid.Column
            mobile={16}
            tablet={16}
            computer={11}
            largeScreen={10}
            widescreen={10}
            only='computer'
          >
            <Grid.Column>
              <p className='only-computer'>{t('finder.what')}</p>
              <div
                className='ui stackable four large buttons'
                style={{ paddingTop: '8px' }}
                aria-label={t('finder.what')}
              >
                <Button onClick={() => handleNavigation()} icon inverted>
                  <span style={{ marginLeft: -12 }}>
                    {t('finder.all-services')}
                  </span>
                </Button>
                <Button
                  onClick={() => handleNavigation('tours')}
                  icon
                  labelPosition='left'
                  inverted
                >
                  <span style={{ marginLeft: -12 }}>{t('finder.tour')}</span>
                  <i className='icon ta-roundtrip large' />
                </Button>
                <Button
                  onClick={() => handleNavigation('hotels')}
                  icon
                  labelPosition='left'
                  inverted
                >
                  <span style={{ marginLeft: -12 }}>{t('finder.hotel')}</span>
                  <i className='icon ta-hotel large' />
                </Button>
                <Button
                  onClick={() => handleNavigation('trips')}
                  icon
                  labelPosition='left'
                  inverted
                >
                  <span style={{ marginLeft: -12 }}>{t('finder.trip')}</span>
                  <i className='icon ta-excursion large' />
                </Button>
              </div>
            </Grid.Column>
          </Grid.Column>

          {/* <Grid.Column
            mobile={16}
            tablet={16}
            computer={3}
            largeScreen={3}
            widescreen={3}
          >
            <p className='only-computer'>&nbsp;</p>
            <Button
              style={{ marginTop: '8px', width: '100%' }}
              onClick={handleNavigation}
              size='large'
              icon
              labelPosition='right'
            >
              {t('finder.search')}
              <i className='icon ta-search-thick large' />
            </Button>
          </Grid.Column> */}
        </Grid.Row>
      </Grid>
    </Container>
  )
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapStateToProps = ({ filters: { options } }) => {
  return { filterOptions: options }
}

const mapDispatchTopProps = {
  setFilters: filtersActions.setFilters
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchTopProps)(Finder)
)
