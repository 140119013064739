import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Segment, Header } from 'semantic-ui-react'
import { withTranslation, Trans } from 'react-i18next'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'

import { getFluidImage, getRawImage } from '../../utils/images'
import Finder from '../Filters/Finder'
import Footer from '../Footer/Footer'
import ItemSlider from '../Items/ItemSlider'
import CountrySlider from '../Items/CountrySlider'
import Specials from '../Items/Specials'
// import BlogPosts from '../Blog/BlogPosts'
import Navigation from '../Navigations/Navigation'
import TestimonialsSlider from '../Items/TestimonialsSlider'
import Advantages from '../Common/Advantages'
import ScrollToTopButton from '../Common/ScrollToTopButton'

import { filtersActions } from 'components/Filters/state'
import { scrollToTop, relocalizeLink } from 'utils/helpers'
import { LIVE_URL } from 'utils/constants'

class HomepageLayout extends Component {
  componentDidMount = () => {
    const { resetFilters } = this.props
    resetFilters()

    scrollToTop()
  }

  render() {
    const {
      t,
      texts,
      countries,
      items,
      categories,
      testimonials,
      specials,
      // blogs,
      image,
      finderOptions,
      pageContext,
      i18n
    } = this.props

    const textHomepage = texts.find(
      ({ node: { section } }) => section === 'homepage'
    )

    const lang = i18n.language
    const otherLang = lang === 'de' ? 'fr' : 'de'
    const alternateHref = LIVE_URL + relocalizeLink(otherLang, pageContext)

    return (
      <>
        <Helmet>
          <html lang={lang} />
          <link rel='alternate' hreflang={otherLang} href={alternateHref} />

          <title>Tischler Reisen</title>
          <meta
            name='description'
            content={
              textHomepage && textHomepage.node.content
                ? textHomepage.node.content
                : 'Tischler Reisen'
            }
          />
          <meta name='image' content={getRawImage({ cloudinary: image })} />

          <meta property='og:title' content='tischler' />
          <meta
            property='og:description'
            content={
              textHomepage && textHomepage.node.content
                ? textHomepage.node.content
                : 'Tischler Reisen'
            }
          />
          <meta
            property='og:image'
            content={getRawImage({ cloudinary: image })}
          />

          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:creator' content='Tischler Reisen' />
          <meta name='twitter:title' content='Tischler Reisen' />
          <meta
            name='twitter:description'
            content={
              textHomepage && textHomepage.node.content
                ? textHomepage.node.content
                : 'Tischler Reisen'
            }
          />
          <meta
            name='twitter:image'
            content={getRawImage({ cloudinary: image })}
          />
        </Helmet>

        <Navigation
          pageContext={pageContext}
          countries={countries}
          categories={categories}
        />

        <div className='content-wrapper'>
          {image && (
            <>
              <div className='max-tablet'>
                <Img
                  loading='eager'
                  fadeIn={false}
                  fluid={getFluidImage({
                    cloudinary: image.node.cloudinary,
                    transformation: image.node.transformation,
                    ar: 2.5,
                    q: 'eco',
                    maxWidth: 300
                  })}
                  alt={image.node.alt || image.node.title}
                />
              </div>
              <div className='min-tablet'>
                <Img
                  loading='lazy'
                  fadeIn={false}
                  fluid={getFluidImage({
                    cloudinary: image.node.cloudinary,
                    transformation: image.node.transformation,
                    ar: 2.5,
                    q: 'eco'
                  })}
                  alt={image.node.alt || image.node.title}
                />
              </div>
            </>
          )}

          <Container
            style={{
              marginTop: '-4.5em'
            }}
          >
            <Segment inverted color='red' padded>
              <Finder options={finderOptions} />
            </Segment>

            <Segment vertical basic textAlign='center' padded='very'>
              <Header as='h1' textAlign='center'>
                <Trans i18nKey='homepage.intro.header'>
                  <span className='tour-red bold'>Tischler Reisen</span>
                </Trans>
              </Header>
              <Header as='h2' textAlign='center'>
                <Trans i18nKey='homepage.intro.h2'>
                  Die persönliche Art des Reisens
                </Trans>
              </Header>
              <Header as='h3'>
                {/* <p
                  style={{ fontWeight: 300 }}
                  dangerouslySetInnerHTML={{
                    __html: textHomepage && textHomepage.node.content
                  }}
                /> */}
                <p style={{ fontWeight: 300 }}>{t('homepage.intro.text')}</p>
              </Header>

              <Advantages />
            </Segment>
          </Container>

          <ItemSlider
            items={items}
            label={{
              black: t('homepage.carousel.items.black'),
              red: t('homepage.carousel.items.red')
            }}
          />

          <CountrySlider countries={countries} />

          {!!specials.length && <Specials specials={specials} />}

          <Segment inverted color='red' padded='very' textAlign='center'>
            <Header as='h3' inverted>
              {t('homepage.highlight-line1')}
              <br />
              <span className='light'>
                {t('homepage.highlight-phoneLabel')}{' '}
                <a
                  href={`tel:${t('homepage.highlight-phone')}`}
                  className='white-link'
                >
                  {t('homepage.highlight-phone')}
                </a>
                {t('homepage.highlight-days')}
              </span>
            </Header>
          </Segment>

          {/* <BlogPosts blogs={blogs} /> */}

          {!!testimonials.length && (
            <TestimonialsSlider testimonials={testimonials} />
          )}
        </div>

        <Footer pageContext={pageContext} />

        <ScrollToTopButton />
      </>
    )
  }
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapDispatchTopProps = {
  resetFilters: filtersActions.resetFilters
}

export default withTranslation()(
  connect(null, mapDispatchTopProps)(HomepageLayout)
)
