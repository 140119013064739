import React from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { Card, Segment, Header, Container } from 'semantic-ui-react'
import Img from 'gatsby-image'
import { sortBy } from 'lodash'

import { getFluidImage } from '../../utils/images'

const Specials = ({ specials, t }) => {
  return (
    <Container>
      <Segment padded='very' vertical basic>
        <Header as='h2' className='specials__header'>
          <Trans i18nKey='nav-specials.header'>
            Our <span className='color-red'>Specials</span>
          </Trans>
        </Header>
        <Card.Group itemsPerRow={3} stackable className='specials__cards'>
          {sortBy(specials, ['node.prio']).map(({ node }) => {
            const {
              id,
              title,
              body,
              image: { cloudinary, alt, title: imgTitle },
              link,
              target,
            } = node

            return (
              <Card key={id}>
                <a href={link.id} rel='noopener noreferrer' aria-label={title} target={target}>
                  <Img
                    loading='lazy'
                    fadeIn={false}
                    fluid={getFluidImage({ cloudinary, maxWidth: 768 })}
                    alt={alt || imgTitle}
                  />
                </a>
                <Card.Content style={{ padding: '1em 0' }}>
                  <Card.Header>
                    <a
                      href={link.id}
                      rel='noopener noreferrer'
                      aria-label={title}
                      target={target}
                    >
                      {title}
                    </a>
                  </Card.Header>
                  <Card.Description>
                    {body}{' '}
                    <a
                      href={link.id}
                      rel='noopener noreferrer'
                      aria-label={title}
                      target={target}
                    >
                      <i className='icon red ta-arrow-1-right' />
                    </a>
                  </Card.Description>
                </Card.Content>
              </Card>
            )
          })}
        </Card.Group>
      </Segment>
    </Container>
  )
}

export default withTranslation()(Specials)
