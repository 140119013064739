import React from 'react'
import { Grid, Button, Icon } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
// import { chunk }  'lodash'
import { Link } from 'gatsby'

const Advantages = ({ t }) => {
  const items = t('homepage.advantages.items', { returnObjects: true })

  return (
    <>
      <Grid stackable columns={3} style={{ margin: '50px 0 25px' }}>
        {items &&
          Array.isArray(items) &&
          items.map((a, index) => (
            <Grid.Column
              textAlign='left'
              key={index}
              style={{ display: 'flex', padding: '0.5rem 1rem' }}
            >
              <Icon
                className='ta-hotel-t-outline'
                fitted
                style={{ color: '#a6b744' }}
              />
              <div style={{ paddingLeft: 15 }}>{a}</div>
            </Grid.Column>
          ))}
      </Grid>

      <Link to={t('homepage.advantages.link.url')}>
        <Button size='large' color='red' icon labelPosition='right' inverted>
          {t('homepage.advantages.link.text')}
          <Icon className='ta-arrow-1-right' />
        </Button>
      </Link>
    </>
  )
}

export default withTranslation()(Advantages)
